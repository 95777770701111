import "@/util/handleError";
import "@/util/setupServiceWorker";

import React from "@/lib/teact/teact";
import TeactDOM from "@/lib/teact/teact-dom";

import { getActions, getGlobal } from "@/global";
import "@/global/init";
import {
  isPermissionGranted,
  requestPermission,
} from "@tauri-apps/plugin-notification";
import { DEBUG } from "@/config";

import App from "@/App";

import "@/styles/index.scss";
import { SentryManager } from "@/sentry";

if (DEBUG) {
  console.log(">>> INIT");
}

try {
  const permission = await requestPermission();
} catch (e) {
  console.error(e);
}

getActions().init();
SentryManager.init();

if (DEBUG) {
  console.log(">>> START INITIAL RENDER");
}

console.log("DEBUG: sentry initial", {
  enabled: SentryManager.clientOptions.enabled,
  environment: SentryManager.clientOptions.environment,
  globalScope: SentryManager.globalScope,
});

window.electron?.restoreLocalStorage();
TeactDOM.render(<App />, document.getElementById("root"));

if (DEBUG) {
  console.log(">>> FINISH INITIAL RENDER!");
}

if (DEBUG) {
  document.addEventListener("dblclick", () => {
    console.warn("GLOBAL STATE", getGlobal());
  });
}
